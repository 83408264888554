import React from 'react'
import { styled } from '@mui/material'
import GoogleMapReact from 'google-map-react'

import gmapStyle from 'public/constants/gmap-style'
import Marker from 'public/images/icons/mapMarker.svg'
// import Marker from 'public/images/icons/blog.svg'

interface Props {
  long: number
  lat: number
}

const Wrapper = styled('div')(({ theme }) => ({
  width: '100%',
  height: '300px',
  [theme.breakpoints.up('lg')]: {
    height: '500px',
  },
}))

const StyledMarker = styled(Marker)({
  width: '46px',
  transform: 'translateY(-105%) translateX(-50%)',
})

const handleApiLoaded = (map: google.maps.Map) => {
  map.panBy(0, -23)
}

const Map = ({ long, lat }: Props) => {
  const defaultProps = {
    center: {
      lat: lat,
      lng: long,
    },
    zoom: 17,
  }

  return (
    <Wrapper>
      <GoogleMapReact
        bootstrapURLKeys={{ key: `${process.env.GATSBY_GMAP_API_KEY}` }}
        center={defaultProps.center}
        zoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => handleApiLoaded(map)}
      >
        <StyledMarker lat={lat} lng={long} />
      </GoogleMapReact>
    </Wrapper>
  )
}

export default Map
