import React, { memo } from 'react'
import { Box, Grid, Typography, styled } from '@mui/material'

import { DirectionsTransit } from '@mui/icons-material'

import Flex from '_core/components/atoms/layout/Flex'
import Card from '_core/components/atoms/cards/Card'
import LineBreaks from '_core/components/atoms/typography/LineBreaks'
import HeadingDivider from 'public/components/atoms/dividers/HeadingDivider'
import Map from 'public/components/atoms/maps/Map'
import ExternalLink from '_core/components/atoms/links/ExternalLink'

interface Props {
  office: Queries.DatoCmsOfficeLocation
  index: number
}

const StyledCard = styled(Card)(({ theme }) => ({
  ['&:last-of-type']: {
    marginBottom: 0,
  },
  [theme.breakpoints.down('lg')]: {
    ['.map-col']: {
      order: '0 !important',
    },
    ['.content-col']: {
      order: '1 !important',
    },
  },
  ['&:nth-of-type(even)']: {
    ['.map-col']: {
      order: 0,
    },
    ['.content-col']: {
      order: 1,
    },
  },
}))

const MapWrapper = styled('div')(({ theme }) => ({
  height: '300px',
  [theme.breakpoints.up('lg')]: {
    height: '500px',
  },
}))

const OfficeCard = ({ office, index }: Props) => {
  return (
    <StyledCard sx={{ mb: { xs: 2, lg: 3, xl: 4 } }}>
      <Grid
        container
        spacing={0}
        sx={{ alignItems: 'center', justifyContent: 'center' }}
      >
        <Grid item xs={12} lg={6} className="content-col">
          <Box p={3} className="details">
            <Flex center>
              <Box sx={{ textAlign: 'center' }}>
                <Box component="h2" typography="h4" textAlign="center">
                  {office.name}
                </Box>
                <HeadingDivider sx={{ mt: 1, mb: 3, mx: 'auto' }} />
                <Typography variant="smallText" sx={{ mb: 2 }}>
                  Address
                </Typography>
                <LineBreaks
                  text={office.address}
                  keyId={office.originalId}
                  breakpoint="xs"
                />
                {office.closestStation && (
                  <Flex alignCenter mt={5}>
                    <DirectionsTransit sx={{ fontSize: 'inherit' }} />
                    <Flex alignCenter ml="5px">
                      <Typography variant="smallText">
                        Closest Station:
                      </Typography>
                    </Flex>
                    <Typography
                      variant="smallText"
                      sx={{ ml: 1, fontWeight: 'fontWeightRegular' }}
                    >
                      {office.closestStation}
                    </Typography>
                  </Flex>
                )}
                {(office.telephoneNumber || office.emailAddress) && index > 0 && (
                  <Box mt={2}>
                    <Typography variant="smallText">Contact</Typography>
                  </Box>
                )}
                {office.telephoneNumber && index > 0 && (
                  <Box>
                    T:{' '}
                    <ExternalLink
                      href={`tel:${office.telephoneNumber.replace(/\s/g, '')}`}
                    >
                      {office.telephoneNumber}
                    </ExternalLink>
                  </Box>
                )}
                {office.emailAddress && index > 0 && (
                  <Box>
                    E:{' '}
                    <ExternalLink href={`mailto:${office.emailAddress}`}>
                      {office.emailAddress}
                    </ExternalLink>
                  </Box>
                )}
              </Box>
            </Flex>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6} className="map-col">
          <Map
            lat={office.location.latitude}
            long={office.location.longitude}
          />
        </Grid>
      </Grid>
    </StyledCard>
  )
}

export default memo(OfficeCard)
