import React from 'react'
import { graphql } from 'gatsby'
import { Box, Container, styled } from '@mui/material'

import Section from '_core/components/atoms/layout/Section'

import Layout from 'public/components/organisms/global/Layout'
import PageTitleHero from 'public/components/organisms/hero/PageTitleHero'
import OfficeCard from 'public/components/molecules/cards/office/OfficeCard'
import SectionHeading from 'public/components/molecules/sections/SectionHeading'
import FollowButtons from 'public/components/molecules/footer/FollowButtons'
import ExternalLink from '_core/components/atoms/links/ExternalLink'

interface Props {
  data: Queries.ContactQuery
}

const StyledExternalLink = styled(ExternalLink)({
  position: 'relative',
})

const Contact = ({ data }: Props) => {
  const page = data.datoCmsLandingPage
  const offices = data.allDatoCmsOfficeLocation.edges

  return (
    <Layout
      metadata={{
        seoTags: page.seoMetaTags,
      }}
    >
      <PageTitleHero
        mainHeading={page.heroMainHeading}
        smallHeading={page.heroSmallHeading}
        imageLandscape={page.imageLandscape}
        imagePortrait={page.imagePortrait}
      >
        {offices[0].node.telephoneNumber && offices[0].node.emailAddress && (
          <>
            <Box className="large-text" mt={2} mb={4}>
              T:{' '}
              <StyledExternalLink
                href={`tel:${offices[0].node.telephoneNumber
                  .replace('(0)', '')
                  .replace(/\s/g, '')}`}
              >
                {offices[0].node.telephoneNumber}
              </StyledExternalLink>
              <br />
              E:{' '}
              <StyledExternalLink
                href={`mailto:${offices[0].node.emailAddress}`}
              >
                {offices[0].node.emailAddress}
              </StyledExternalLink>
            </Box>
            <FollowButtons />
          </>
        )}
      </PageTitleHero>
      <Section bgcolor="lightGrey" vSpace>
        <SectionHeading heading="Office Locations" />
        <Container maxWidth="xl">
          {offices.map((item: any, index: number) => {
            const office: Queries.DatoCmsOfficeLocation = item.node
            return (
              <OfficeCard
                key={office.originalId}
                office={office}
                index={index}
              />
            )
          })}
        </Container>
      </Section>
    </Layout>
  )
}

export default Contact

export const pageQuery = graphql`
  query Contact {
    datoCmsLandingPage(originalId: { eq: "1481503" }) {
      ...LandingPageTitleHero
      textSectionHeroText
      textSectionSmallHeading
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    allDatoCmsOfficeLocation(sort: { fields: position, order: ASC }) {
      edges {
        node {
          originalId
          name
          telephoneNumber
          emailAddress
          headOffice
          closestStation
          address
          location {
            latitude
            longitude
          }
        }
      }
    }
  }
`
