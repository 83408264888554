import React, { memo } from 'react'
import { Box, styled } from '@mui/material'
import striptags from 'striptags'

import { BreakpointProps } from '_core/constants/breakpoint-props'
import Flex from '_core/components/atoms/layout/Flex'

const Line = styled('span', {
  shouldForwardProp: (prop) => prop !== 'breakpoint',
})<BreakpointProps>(({ breakpoint, theme }) => ({
  [theme.breakpoints.up(breakpoint)]: {
    display: 'block',
    '&:nth-of-type(even)': {
      alignSelf: 'flex-end',
    },
  },
}))

export interface LineBreaksCoreProps extends BreakpointProps {
  keyId: string
}

export interface LineBreaksProps extends LineBreaksCoreProps {
  text: string
  alternateLines?: boolean
}

const LineBlocks = ({
  text,
  alternateLines,
  keyId,
  breakpoint,
}: LineBreaksProps) => {
  const lines = striptags(text, ['span', 'em', '<p>']).split('\n')

  let lineProps = {}
  if (alternateLines) {
    lineProps = {
      className: 'alt-lines',
    }
  }

  return (
    <>
      {lines.map((line, index) => {
        return (
          <Line
            breakpoint={breakpoint}
            key={`${keyId}-${index}`}
            dangerouslySetInnerHTML={{
              __html: line,
            }}
            {...lineProps}
          />
        )
      })}
    </>
  )
}

const LineBreaks = ({
  text,
  keyId,
  breakpoint,
  alternateLines,
}: LineBreaksProps) => {
  return (
    <>
      {alternateLines ? (
        <Flex component="span" column alignStart>
          <LineBlocks
            text={text}
            keyId={keyId}
            breakpoint={breakpoint}
            alternateLines={alternateLines}
          />
        </Flex>
      ) : (
        <LineBlocks
          text={text}
          keyId={keyId}
          breakpoint={breakpoint}
          alternateLines={false}
        />
      )}
    </>
  )
}

export default memo(LineBreaks)
